import DelineationTask from "./pages/Task";
import DelineationReviewSingle from "./pages/ReviewSingle";
import DelineationReviewSummary from "./pages/ReviewSummary";
import DelineationSummary from "./pages/Summary";
import DelineationSummaryDetail from "./pages/SummaryDetail";
import SummarySuperuser from "./pages/SummarySuperuser";

const routes = [
  { path: "task", element: DelineationTask },
  { path: "summary", element: DelineationSummary },
  { path: "summary/detail", element: DelineationSummaryDetail },
  { path: "review", element: DelineationReviewSummary },
  { path: "review/single", element: DelineationReviewSingle },
  { path: "superuser", element: SummarySuperuser },
];
export default routes;
