import QuestionTypeGroup from "./QuestionGroup";
function QuestionBlock({
  allData,
  data,
  current,
  categorizedImages,
  crop_index,
  radioValues,
  handleRadioChange,
  cropQuestions,
}) {
  let title;
  if (crop_index !== "-1") {
    const crop = data.crops[crop_index]?.crop;
    title = `Crop ${parseInt(crop_index) + 1} : ${crop}`;
    if (crop == "other") {
      title += ` (${data.crops[crop_index]?.otherCropType})`;
    }
  } else {
    title = "Location";
  }

  return (
    <div className="mt-4 border-b border-gray-200 bg-white sm:rounded-lg shadow divide-y">
      <div className="px-2 py-2 min-w-0 text-center border-gray-300">
        {title}
      </div>
      {/* block of question_type */}
      {/* Each crops might have multiple question_type */}
      {Object.entries(cropQuestions)
        .sort()
        .map(([question_type, questions]) => {
          return (
            <div
              className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 border-gray-300 p-4"
              key={question_type}
            >
              <QuestionTypeGroup
                question_type={question_type}
                allData={allData}
                data={data}
                current={current}
                categorizedImages={categorizedImages}
                crop_index={crop_index}
                radioValues={radioValues}
                handleRadioChange={handleRadioChange}
                questions={questions}
              />
            </div>
          );
        })}
    </div>
  );
}

export default QuestionBlock;
