import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik, Form, useFormikContext } from "formik";

import { get } from "../../common/utils/api";

import { AgGridReact } from "ag-grid-react";

import DateInput from "../../common/components/form/DateInput.js";
import Select from "../../common/components/form/Select.js";
import Radio from "../../common/components/form/Radio.js";
import LoadingModal from "../../common/components/LoadingModal.js";
import { formatDate, calculatePercentage } from "../../common/utils/helpers";

import { useUser } from "../../user/utils/user";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function ReviewForm({ groupOptions, t }) {
  const { initialValues, submitForm } = useFormikContext();
  // Automatically refresh form if input values change
  useEffect(() => {
    if (initialValues) {
      submitForm();
    }
  }, [initialValues, submitForm]);
  return (
    <React.Fragment>
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div className="space-y-6 sm:space-y-5">
          {groupOptions.length > 0 && (
            <Select label={"Group"} name={"formGroup"} options={groupOptions} />
          )}
          <DateInput label={"Start date"} name={"formStartDate"} />
          <DateInput label={"End date"} name={"formEndDate"} />
        </div>
      </div>
      <div className="py-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.generate", "Generate Report")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function DelineationSummary({ showAlert, t }) {
  let [summaryData, setSummaryData] = useState([]);
  let [totalData, setTotalData] = useState([]);
  let [groupOptions, setGroupOptions] = useState([]);

  const navigate = useNavigate();
  const { user } = useUser();

  // Default date range: past three days for now
  let today = new Date();
  let daysBefore = new Date();
  daysBefore.setDate(today.getDate() - 3);

  // Use session storage for the existing user and dates if they exist
  const initialValues = {
    formGroup: sessionStorage.getItem("group") || "All",
    formStartDate:
      sessionStorage.getItem("startDate") || formatDate(daysBefore),
    formEndDate: sessionStorage.getItem("endDate") || formatDate(today),
    formShowCompleted: sessionStorage.getItem("showCompleted") || "no",
  };

  let totalColumnDefs = [
    { field: "email", headerName: t("user.email", "Email") },
    {
      field: "count",
      headerName: t("user.count", "# submissions"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "total",
      headerName: t("user.total", "# parcels"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "reviewed",
      headerName: t("user.reviewed", "# reviewed"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "approved",
      headerName: t("user.approved", "# approved"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "approved_percent",
      headerName: t("common.approved", "% approved"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.approved_percent.toFixed(1),
    },
  ];

  let summaryColumnDefs = [
    {
      field: "view",
      headerName: "",
      width: 80,
      cellRenderer: () => t("plot.review", "view"),
      onCellClicked: function (params) {
        navigate(`/delineation2/summary/detail?task_id=${params.data.id}`);
      },
      cellClass: ["text-cyan-600", "hover:text-cyan-900", "cursor-pointer"],
    },
    { field: "email", headerName: t("user.email", "Email") },
    {
      field: "created_at",
      headerName: t("common.createddate", "Date"),
      width: 120,
      valueFormatter: (params) => params.data.created_at.toISOString(),
    },
    {
      field: "total",
      headerName: t("user.total", "# parcels"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "reviewed",
      headerName: t("user.reviewed", "# reviewed"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "approved",
      headerName: t("user.approved", "# approved"),
      width: 120,
      type: "numericColumn",
    },
    {
      field: "approved_percent",
      headerName: t("common.approved", "% approved"),
      type: "numericColumn",
      valueFormatter: (params) => params.data.approved_percent.toFixed(1),
    },
  ];

  const getGroups = async () => {
    get("/users/groups/").then((response) => {
      setGroupOptions(["All", ...response.data]);
    });
  };

  const getData = async (
    group_selected,
    start_date,
    end_date,
    setSubmitting,
  ) => {
    // Format the start_date & end_date to 00:00 & 23:59 in local time
    const start_time = new Date(start_date + " 00:00:00.0").toISOString();
    const end_time = new Date(end_date + " 23:59:59.999").toISOString();
    get(
      `/parcels2/summary/?group=${group_selected}&start_date=${start_time}&end_date=${end_time}`,
    )
      .then((response) => response.data)
      .then((data) => {
        data.forEach((d) => {
          d.created_at = new Date(d.created_at);
          d.approved = d.reviewed - d.rejected;
          d.approved_percent = calculatePercentage(d.approved, d.reviewed);
        });

        // Show most recent data first, with user as the tiebreaker
        data = data.sort(function (a, b) {
          let order1 = a.email.localeCompare(b.email);
          let order2 = b.created_at - a.created_at;

          if (order2 === 0) {
            return order1;
          } else {
            return order2;
          }
        });

        setSummaryData(data);

        const totals = {};
        data.forEach((d) => {
          const total = totals[d.email] || {
            count: 0,
            total: 0,
            reviewed: 0,
            approved: 0,
            rejected: 0,
          };

          total.count += 1;
          total.total += d.total;
          total.reviewed += d.reviewed;
          total.approved += d.approved;
          total.rejected += d.rejected;

          totals[d.email] = total;
        });
        let totalData = Object.entries(totals).map(([k, v]) => {
          return {
            email: k,
            ...v,
            approved_percent: calculatePercentage(v.approved, v.reviewed),
          };
        });
        // Show most recent data first, with user as the tiebreaker
        totalData = totalData.sort(function (a, b) {
          return a.email.localeCompare(b.email);
        });
        setTotalData(totalData);
      })
      .finally(() => setTimeout(() => setSubmitting(false), 300));
  };

  const grid = useRef();
  const onGridReady = (params) => {
    grid.api = params.api;
    grid.api.sizeColumnsToFit();
  };
  const gridTotal = useRef();
  const onGridTotalReady = (params) => {
    gridTotal.api = params.api;
    gridTotal.api.sizeColumnsToFit();
  };

  const exportData = (grid) => {
    console.log(grid);
    grid.current.api.exportDataAsCsv({
      allColumns: true,
    });
  };

  useEffect(() => {
    if (user?.user?.is_superuser) {
      getGroups();
    }
  }, [user]);

  function onSubmit(value, { setSubmitting }) {
    getData(
      value.formGroup,
      value.formStartDate,
      value.formEndDate,
      setSubmitting,
    );
    sessionStorage.setItem("group", value.formGroup);
    sessionStorage.setItem("startDate", value.formStartDate);
    sessionStorage.setItem("endDate", value.formEndDate);
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 bg-white border-gray-200 shadow rounded-md pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.summary", "Generate Activity Summary")}
            </h3>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                <LoadingModal open={isSubmitting} />
                <Form className="space-y-8 divide-y divide-gray-200">
                  <ReviewForm groupOptions={groupOptions} t={t} />
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewTotal", "Totals")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(gridTotal)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div
          className="ag-theme-alpine mt-4"
          style={{ height: 400 }}
          data-testid="summary-totals"
        >
          <AgGridReact
            ref={gridTotal}
            rowData={totalData}
            columnDefs={totalColumnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            onGridReady={onGridTotalReady}
          ></AgGridReact>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("common.reviewSummary", "All Submissions")}
            </h3>
          </div>
        </div>
        <div className="mb-2 flex justify-end">
          <button
            onClick={() => exportData(grid)}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
          >
            {t("common.exportToCSV", "Export to CSV")}
          </button>
        </div>
        <div
          className="ag-theme-alpine mt-4"
          style={{ height: 400 }}
          data-testid="summary-all"
        >
          <AgGridReact
            ref={grid}
            rowData={summaryData}
            columnDefs={summaryColumnDefs}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(DelineationSummary);
